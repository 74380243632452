import React, { useState } from 'react'
import "./send-prescription.scss"
import { Toast } from '../../../utils/SwalUti'
import { post } from '../../../utils/fetchAPI'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'

const SendPrescription = ({ patientDetails, visitId, patientId, doctorId, setShow }) => {
    const [phoneNumber, setPhoneNumber] = useState(patientDetails?.phone || "")
    const [email, setEmail] = useState(patientDetails?.email || "")
    const [isMsgSubmitting, setIsMsgSubmitting] = useState(false)
    const [isEmailSubmitting, setEmailIsSubmitting] = useState(false)
    const { selectedRole } = useSelector((state) => state.auth);
    const sentByMsg = async (e) => {
        e.preventDefault()
        setIsMsgSubmitting(true)
        await post(`/sms`, {
            doctorId: doctorId,
            text: "This is your prescription link: " + " " + process.env.REACT_APP_WEB_BASE_URL + "prescription" + "/" + visitId + "/" + selectedRole?._id,
            phone: phoneNumber
        })
            .then((res) => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: '',
                        title: "Prescription sent successfully"
                    })
                }
            })
            .catch((error) => {
                setIsMsgSubmitting(false)
            })
            .finally(() => setIsMsgSubmitting(false));
    }
    const sentByEmail = async (e) => {
        setEmailIsSubmitting(true)
        e.preventDefault()
        await post(`/email`, {
            email: email,
            subject: "Prescription",
            body: "This is your prescription link: " + " " + process.env.REACT_APP_WEB_BASE_URL + "prescription" + "/" + visitId + "/" + selectedRole?._id,
        })
            .then((res) => {
                if (res.status === 200) {
                    Toast.fire({
                        icon: '',
                        title: "Prescription sent successfully"
                    })
                }
            })
            .catch((error) => {
                setEmailIsSubmitting(false)
            })
            .finally(() => setEmailIsSubmitting(false));
    }

    const handleCopy = () => {
        const link = `${process.env.REACT_APP_WEB_BASE_URL}prescription/${visitId}/${selectedRole?._id}`;
        navigator.clipboard.writeText(link).then(() => {
            Toast.fire({
                icon: '',
                title: "Prescription link copied"
            });

        }).catch(err => {
            console.error("Failed to copy the text: ", err);
        });
    };
    return (
        <div className="prescription-sent pb-4">
            <div className='header pb-0 mb-0 d-flex justify-content-between'>
                <p className="title mb-0">Send prescription to patient</p>
                <p className="title mb-0 text-danger" onClick={() => setShow(false)} style={{ cursor: "pointer" }}> <FontAwesomeIcon className='mx-2' size='lg' icon={faXmark} /></p>
            </div>
            <div className='w-100 px-2 mb-1 py-0 my-0 d-flex' onClick={handleCopy}>
                <div style={{ overflowX: "scroll", textWrap: "nowrap", width: "82%" }}>
                    <p style={{fontSize:"13px",cursor: "copy", color:"blue"}} className='mt-2'>{process.env.REACT_APP_WEB_BASE_URL}prescription/${visitId}/${selectedRole?._id}</p>
                </div>
                <div>
                    <p className='my-2 py-0 px-2 rounded ml-2' style={{ backgroundColor: "#12AB81", fontSize: "13px", color: "white", cursor: "copy" }}>Copy link</p>
                </div>
            </div>
            <form

                onSubmit={(e) => sentByMsg(e)}
                action=""
                id=""
                className="custom-form input-section"
                encType="multipart/form-data"
            >
                <div className=" d-flex my-0 py-0 px-2 mb-1">
                    <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '35%' }}>
                        Phone Number:
                    </label>
                    <input
                        className="form-control"
                        autoComplete="off"
                        pattern="(^(01){1}[3-9]{1}\d{8})$"
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="Enter Phone Number"
                        value={phoneNumber || ""}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value)
                        }}
                    />
                    <button disabled={isMsgSubmitting} className='btn btn-sm px-3 submit-btn py-0 mx-2' type="submit">{!isMsgSubmitting && "Send"}
                        {isMsgSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>
            </form>
            <form
                onSubmit={(e) => sentByEmail(e)}
                action=""
                id=""
                className="custom-form input-section"
                encType="multipart/form-data"
            >
                <div className=" d-flex my-0 py-0 px-2 mb-1">
                    <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '35%' }}>
                        Email:
                    </label>
                    <input
                        className="form-control"
                        autoComplete="off"
                        type="email"
                        placeholder="Enter email"
                        value={email || ""}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <button disabled={isEmailSubmitting} className='btn btn-sm px-3 submit-btn py-0 mx-2' type="submit">{!isEmailSubmitting && "Send"}
                        {isEmailSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SendPrescription

import React, { useEffect, useRef, useState } from "react";
import "./staff.scss";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../components/common/modal/modal";
import Pagination from "react-js-pagination";
import { deleteStaff } from "../../service/api/appointment-api";
// import { useNavigate } from "react-router-dom";
import { getStaffs } from "../../store/slices/staffSlice";
import StaffCreateForm from "../../components/staff/staff-create-form";

const Staff = () => {
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState();
    const ref = useRef([]);
    const [length, setLength] = useState(5);
    let { isLoading, pagination, allStaffs, noStaffFound } =
        useSelector((state) => state.staff);
    const { currentPage, perPage, total } = pagination;
    const [show, setShow] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    // const navigate = useNavigate()

    useEffect(() => {
        dispatch(
            getStaffs({
                search: "",
                per_page: length,
                current_page: currentPage,
            })
        );
    }, []);

    const refresh = () => {
        dispatch(
            getStaffs({
                search: "",
                per_page: length,
                current_page: currentPage,
            })
        );
    }

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (
                selectedRow &&
                ref.current[selectedRow] &&
                !ref.current[selectedRow].contains(e.target)
            ) {
                setSelectedRow(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [selectedRow]);

    const index = (page = 1, search = "", per_page) => {
        dispatch(
            getStaffs({
                search: search,
                per_page: per_page,
                current_page: page,
            })
        );
    };

    const getSearchedAppointmentFun = (e) => {
        setSearchKey(e.target.value);
        e.preventDefault();
        index(1, e.target.value, length);
    };



    const handleKeyDown = (event) => {
        // if (event.keyCode == 13) {
        //     event.preventDefault();
        //     setShow(true)
        // }
        if (event.keyCode === 27) {
            event.preventDefault();
            setShow(false)
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const deleteStaffFun = async (staff) => {
        await deleteStaff(staff?._id)
          .then((res) => {
            if (res.status === 200) {
              index()
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    return (
        <div>
            {/* -------------- */}
            <section className="content pt-3 content-wrapper main-content appointment-page-container ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="appointment-add d-flex justify-content-end ">
                                        {/* <button
                                            className="btn appointment-add-button"
                                            onClick={() => {
                                                setShow(true);
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                className="appointment-add-button-icon"
                                                icon={faUserPlus}
                                            ></FontAwesomeIcon>{" "}
                                            Staff{" "}
                                        </button> */}
                                        <input
                                            className="appointment-search"
                                            placeholder="Search staff"
                                            type="text"
                                            onChange={(e) => {
                                                getSearchedAppointmentFun(e);
                                            }}
                                        ></input>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div
                                    className="card-body"
                                    style={{ overflowX: "auto", height: "82vh" }}
                                >
                                    <table
                                        id="example2"
                                        className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                                    >
                                        <thead>
                                            {/* table heading */}
                                            <tr>
                                                <th>Staff Name</th>
                                                <th>Role</th>
                                                <th>Location</th>
                                                <th>Phone</th>
                                                <th>Email</th>
                                                <th>Gender</th>
                                                <th>Status</th>
                                                {/* <th className="text-center">Action</th> */}
                                                {/* <th>DETAILS</th> */}
                                            </tr>
                                        </thead>
                                        {noStaffFound ? (
                                            <small className="d-block ml-1">
                                                No staff found
                                            </small>
                                        ) : (
                                            <tbody>
                                                {allStaffs.staffs?.map(
                                                    (staff, index) => {
                                                        return (
                                                            <tr
                                                                key={index}
                                                                className={`status-section`}
                                                            // onClick={()=>console.log(appointment,index)}
                                                            >
                                                                {/* <td className="hide">{index + 1}</td> */}
                                                                <td className="hide">
                                                                    {staff?.user?.name}
                                                                </td>
                                                                <td className="hide">
                                                                    {staff?.user?.type}
                                                                </td>
                                                                <td className="hide">
                                                                    {staff?.location?.name}
                                                                </td>
                                                                <td className="hide">
                                                                    {staff?.user?.phone}
                                                                </td>
                                                                <td className="">
                                                                    {staff?.user?.email}
                                                                </td>
                                                                <td className="">
                                                                    {staff?.user?.gender}
                                                                </td>
                                                                <td style={{ cursor: "pointer" }}>
                                                                    <p
                                                                        className={`mb-0 pb-0 d-flex ${selectedRow ? "disable-btn" : ""
                                                                            }`}
                                                                        onClick={() => {
                                                                            setSelectedRow(staff?._id);
                                                                        }}
                                                                    >
                                                                        <span className="pb-1">
                                                                            {staff?.user?.status}
                                                                        </span>
                                                                        {/* <span
                                                                            title={`click to change option-section `}
                                                                            style={{ marginTop: "2px" }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                size="xs"
                                                                                icon={faEllipsisVertical}
                                                                                className={`ml-2`}
                                                                            />
                                                                        </span> */}
                                                                    </p>
                                                                </td>
                                                                {/* <td className='text-center'><span className='icon'> <FontAwesomeIcon onClick={() => {
                                                                    deleteStaffFun(staff)
                                                                }} icon={faTrash}></FontAwesomeIcon></span></td> */}

                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        )}
                                        {isLoading && <small className="ml-1">Loading...</small>}
                                    </table>
                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                    <div className="float-left">
                                        <div
                                            className="dataTables_info"
                                            id="example2_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            <>
                                                <select
                                                    onChange={(e) => {
                                                        setLength(e.target.value);
                                                        index(1, "", e.target.value);
                                                    }}
                                                >
                                                    <option value={5}>5 entries </option>
                                                    <option value={10}>10 entries </option>
                                                    <option value={20}>20 entries </option>
                                                    <option value={50}>50 entries </option>
                                                    <option value={100}>100 entries </option>
                                                </select>{" "}
                                                &nbsp; Showing {(currentPage - 1) * perPage + 1} to{" "}
                                                {total > currentPage * perPage
                                                    ? currentPage * perPage
                                                    : total}{" "}
                                                of {total} entries
                                            </>
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        {noStaffFound && !isLoading ? (
                                            ""
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <Pagination
                                                    activePage={parseInt(currentPage)}
                                                    itemsCountPerPage={parseInt(perPage)}
                                                    totalItemsCount={parseInt(total)}
                                                    // pageRangeDisplayed={5}
                                                    onChange={(currentPage) => {
                                                        index(currentPage, "", length);
                                                    }}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/*  modal */}
            <CustomModal
                customClass="appointment"
                size="xl"
                show={show}
                setShow={setShow}
                customSize={"size-72"}
                isHeaderHide={true}
            >
                <p className="py-1 my-0 mx-2" style={{fontSize:'14px' ,fontWeight:"600"}}>Create new staff</p>
                <StaffCreateForm refresh={refresh} setShow={setShow}></StaffCreateForm>
            </CustomModal>
        </div>
    );
};

export default Staff;

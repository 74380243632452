import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./complaint.scss"
import { Form, Formik } from 'formik';
import { visitUpdate } from '../../../service/api/visit';
import CustomModal from '../../common/modal/modal';
import { put, post, get, del } from "../../../utils/fetchAPI";
import { showSuccessToast } from '../../../utils/toaster-alert';
import { Icd11 } from '../../common/icd11/icd11';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import CreatableSearchSelect from '../../common/updated-input/createble-search-select/creatable-search-select';

const Complaint = ({ visitDetails = {}, updateState }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const [showAdd, setShowAdd] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [templateInfo, setTemplateInfo] = useState({})
    const [templateList, setTemplateList] = useState([])
    const [presentIllnessList, setPresentIllnessList] = useState([])
    const timeoutIdRef = useRef(null)
    const [showTemplates, setShowTemplates] = useState(false)

    const [showPresentIllness, setShowPresentIllness] = useState(false)
    const [showPresenetDelete, setShowPresenetDelete] = useState(false)

    useEffect(() => {
        if (visitDetails?.doctor) {
            getTemplateList(visitDetails?.doctor)
            getPresentIllnessList(visitDetails?.doctor)
        }
    }, [visitDetails?.doctor])


    // Reason For Visit  
    const setReasonForVisitOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getTemplateList(visitDetails?.doctor, event?.target?.value)) }, 500)

    }
    const handleReasonForVisitAddSubmit = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.reasonForVisit || "") + (data?.reasonForVisit ? "\n" : "") + (value?.reasonForVisit || ""))
        updateComplaint({ reasonForVisit: updatedReasonForVisit, historyOfPresetIllness: data?.historyOfPresetIllness, extraNote: data?.extraNote })
    }
    const handleCreatePastIlless = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.reasonForVisit || "") + (data?.reasonForVisit ? "\n" : "") + (value || ""))
        updateComplaint({ reasonForVisit: updatedReasonForVisit, historyOfPresetIllness: data?.historyOfPresetIllness, extraNote: data?.extraNote })
        createNewComplaintTemplate(value)
    }
    // -----------------

    // Presnet Illness  
    const setPresentIllnessOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getPresentIllnessList(visitDetails?.doctor, event?.target?.value)) }, 500)

    }
    const handlePresentIllnessAddSubmit = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.historyOfPresetIllness || "") + (data?.historyOfPresetIllness ? "\n" : "") + (value?.historyOfPresetIllness || ""))
        updateComplaint({ reasonForVisit: data?.reasonForVisit, historyOfPresetIllness: updatedPresentIlness, extraNote: data?.extraNote })
    }
    const handleCreatePresentIllness = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.historyOfPresetIllness || "") + (data?.historyOfPresetIllness ? "\n" : "") + (value || ""))
        updateComplaint({ reasonForVisit: data?.reasonForVisit, historyOfPresetIllness: updatedPresentIlness, extraNote: data?.extraNote })
        createNewpresenetIllnessTemplate(value)
    }
    // -----------------


    const setInfoFun = (key, value, submit = false) => {
        // let temp = { ...info };
        // setInfo({ ...temp, [key]: value });
        let temp = { ...info, [key]: value };
        setInfo(temp);
        if (submit) {
            onSubmit(temp)
        }
        // clearTimeout(timeoutIdRef.current);
        // timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 800);
    };


    const onSubmit = async (values) => {
        // values.reasonForVisit = info.reasonForVisit;
        // values.historyOfPresetIllness = info.historyOfPresetIllness;
        // values.patient = patientId
        updateComplaint(values)
    };

    const setReasonForvisit = async (value) => {
        let values = {}
        values.reasonForVisit = info?.reasonForVisit + (info?.reasonForVisit ? "\n" : "") + value;
        values.historyOfPresetIllness = info?.historyOfPresetIllness;
        values.extraNote = info?.extraNote
        setInfo(values)
        await updateComplaint(values)
    }
    const setHistoryOfPresentIllness = async (value) => {
        let values = {}
        values.reasonForVisit = info?.reasonForVisit
        values.historyOfPresetIllness = info?.historyOfPresetIllness + (info?.historyOfPresetIllness ? "\n" : "") + value;;
        values.extraNote = info?.extraNote
        setInfo(values)
        await updateComplaint(values)
    }

    function updateComplaint(values) {
        setIsSubmitting(true)
        visitUpdate(values, visitDetails?._id)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    updateState(res.data)
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.reasonForVisit = info?.reasonForVisit;
            values.historyOfPresetIllness = info?.historyOfPresetIllness;
            values.extraNote = info?.extraNote
            updateComplaint(values)

        }
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);


    const createNewComplaintTemplate = async (value = info?.reasonForVisit) => {
        await post(`/doctor/complaints`, { reasonForVisit: value })
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(visitDetails?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const createNewpresenetIllnessTemplate = async (value = info?.historyOfPresetIllness) => {

        await post(`/doctor/presentIllness`, { historyOfPresetIllness: value })
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(visitDetails?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }
    const getTemplateList = async (doctorId, search = "") => {

        await get(`/doctor/complaints/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setTemplateList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }
    const getPresentIllnessList = async (doctorId, search = "") => {

        await get(`/doctor/presentIllness/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setPresentIllnessList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updateTemplate = async (type) => {
        let { historyOfPresetIllness, ...payload } = templateInfo
        await put(`/doctor/${type}/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowAdd(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updatePresnetIllness = async () => {
        let { reasonForVisit, ...payload } = templateInfo
        await put(`/doctor/presentIllness/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowPresentIllness(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deleteTemplate = async (type, id) => {
        await del(`/doctor/${type}/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deletePresentIllness = async (id) => {
        await del(`/doctor/presentIllness/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowPresenetDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }



    useMemo(() => {
        if (visitDetails) {
            setInfo({ reasonForVisit: visitDetails?.reasonForVisit || "", historyOfPresetIllness: visitDetails?.historyOfPresetIllness || "", extraNote: visitDetails?.extraNote || "" });
        }
    }, [visitDetails])







    return (
        <div className='complaint'>
            <div className='complaint-section'>
                {/* <div className='p-0 m-0 d-flex'>
                <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
            </div> */}
                {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
                    {(formik) => (
                        <div className="">
                            <Form
                                action=""
                                id=""
                                className="custom-form input-section position-relative"
                                encType="multipart/form-data"
                            >

                                <div className="row  ">
                                    <div className="col-6 col-md-12  mb-0 pb-0 ">
                                        {/* <Icd11 placeHolder="Search reason for visit" getValue={setReasonForvisit}></Icd11> */}
                                        <CreatableSearchSelect options={templateList} setOptionFun={setReasonForVisitOptionFun} handleSubmit={handleReasonForVisitAddSubmit}
                                            handleCreate={handleCreatePastIlless}
                                        ></CreatableSearchSelect>
                                        {/* <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            Reason For Visit
                                        </label> */}
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                Reason For Visit
                                            </label>
                                            <div className='d-flex'>
                                                <p onClick={() => {
                                                    createNewComplaintTemplate()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        {/* <p onClick={() => {
                                            createNewComplaintTemplate()
                                        }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p> */}
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={5}
                                                placeholder="Reason For Visit"
                                                value={info?.reasonForVisit || ""}
                                                onChange={(e) => {
                                                    setInfoFun("reasonForVisit", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("reasonForVisit", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 mt-5 pt-4">

                                        {/* <Icd11 placeHolder="Search history of present illness" getValue={setHistoryOfPresentIllness}></Icd11> */}
                                        {/* <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            History Of Present Illness
                                        </label> */}
                                        <CreatableSearchSelect options={presentIllnessList} setOptionFun={setPresentIllnessOptionFun} handleSubmit={handlePresentIllnessAddSubmit}
                                            handleCreate={handleCreatePresentIllness}
                                        ></CreatableSearchSelect>
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                History Of Present Illness
                                            </label>
                                            <div className='d-flex'>
                                                <p onClick={() => {
                                                    createNewpresenetIllnessTemplate()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={5}
                                                placeholder="History Of Preset Illness"
                                                value={info?.historyOfPresetIllness || ""}
                                                onChange={(e) => {
                                                    setInfoFun("historyOfPresetIllness", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("historyOfPresetIllness", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 mt-4">
                                        <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            Note
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={3}
                                                placeholder="Note"
                                                value={info?.extraNote || ""}
                                                onChange={(e) => {
                                                    setInfoFun("extraNote", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("extraNote", e.target.value, true);
                                                }}
                                                
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-12  text-center mt-3 d-flex">
                                    <button
                                        type="submit"
                                        className="btn submit-button  mx-auto w-25"
                                        onClick={() => {
                                        }}
                                    >
                                        Save
                                        {isSubmitting && (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                    </button>
                                </div> */}
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>}



                <CustomModal
                    size="md"
                    show={showAdd}
                    isHeaderHide={true}
                >
                    <div className='mt-0  create-template px-2 py-2'>

                        <label className='mt-0 pt-0 d-block'>Reason For Visit</label>
                        <textarea className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.reasonForVisit} onChange={(e) => {
                            setTemplateInfo({ reasonForVisit: e.target.value, historyOfPresetIllness: templateInfo?.historyOfPresetIllness || "", chiefCompliantId: templateInfo?.chiefCompliantId, doctorId: visitDetails?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updateTemplate("complaints")
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowAdd(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="sm"
                    show={showDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deleteTemplate("complaints", templateInfo?.chiefCompliantId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="md"
                    show={showPresentIllness}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <label className='mt-0 pt-0 d-block'>History of present illness</label>
                        <textarea key={12} className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.historyOfPresetIllness} onChange={(e) => {
                            setTemplateInfo({ historyOfPresetIllness: e.target.value, historyOfPresetIllnessId: templateInfo?.historyOfPresetIllnessId, doctorId: visitDetails?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updatePresnetIllness()
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowPresentIllness(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="sm"
                    show={showPresenetDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deletePresentIllness(templateInfo?.presentIllnessId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowPresenetDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="xl"
                    show={showTemplates}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='p-4' >
                        <div className='d-flex justify-content-end mb-1'>
                            <button style={{ background: "red", color: "white" }} onClick={() => setShowTemplates(false)} className='btn py-0'>Close</button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflowX: "scroll" }}>
                            <table className="table table-sm table-hover border" >
                                <div className='d-flex'>
                                    <div className='p-2' style={{ width: "50%", borderRight: "1px solid black", minHeight: "30vh" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}> Chief Complaint</p>
                                        <div>
                                            {templateList?.length > 0 ? <div>
                                                {templateList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.reasonForVisit}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                                {/* <button style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}
                                                                    onClick={() => {
                                                                        let data = { ...info }
                                                                        let updatedReasonForVisit = ((data?.reasonForVisit || "") + (data?.reasonForVisit && complaint?.reasonForVisit ? "\n" : "") + (complaint?.reasonForVisit || ""))
                                                                        updateComplaint({ reasonForVisit: updatedReasonForVisit, historyOfPresetIllness: data?.historyOfPresetIllness, extraNote: data?.extraNote })
                                                                    }}
                                                                    className='btn bg-success w text-end  px-3 my-0 py-0 rounded mx-2 pointer' >Use</button> */}

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ reasonForVisit: complaint?.reasonForVisit || "", chiefCompliantId: complaint?._id, doctorId: visitDetails?.doctor })
                                                                    setShowAdd(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ chiefCompliantId: complaint?._id })
                                                                    setShowDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No chief complaint template found</small>}
                                        </div>
                                    </div>
                                    <div className='p-2' style={{ width: "50%" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}> History Of Presnet Illness</p>
                                        <div>
                                            {presentIllnessList?.length > 0 ? <div>
                                                {presentIllnessList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.historyOfPresetIllness}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                                {/* <button style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }} onClick={() => {
                                                                let data = { ...info }
                                                                let updatedPresentIlness = ((data?.historyOfPresetIllness || "") + (data?.historyOfPresetIllness && complaint?.historyOfPresetIllness ? "\n" : "") + (complaint?.historyOfPresetIllness || ""))
                                                                updateComplaint({ reasonForVisit: data?.reasonForVisit, historyOfPresetIllness: updatedPresentIlness, extraNote: data?.extraNote })
                                                            }} className='btn bg-success w text-end  px-3 my-0 py-0 rounded mx-2 pointer' >Use</button> */}

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ historyOfPresetIllness: complaint?.historyOfPresetIllness || "", historyOfPresetIllnessId: complaint?._id, doctorId: visitDetails?.doctor })
                                                                    setShowPresentIllness(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ presentIllnessId: complaint?._id })
                                                                    setShowPresenetDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No present illness template found</small>}
                                        </div>
                                    </div>
                                </div>
                            </table>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>

    )
}

export default Complaint

import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./carePlan.scss"
import { Form, Formik } from 'formik';
import { carePlanTemplates, setCarePlanTemplate, visitUpdate } from '../../../service/api/visit';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { log } from 'handlebars';
import SearchSelect from '../../common/updated-input/createble-search-select/search-select';
import CustomModal from '../../common/modal/modal';
import { delWithBody, get, post, put } from '../../../utils/fetchAPI';
import NameSectionCarePlan from './name-section';
import UniversalLoaderForComponent from '../../loader/universalLoaderForComponent';

const CarePlan = ({ visitDetails = {}, updateState }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const timeoutIdRef = useRef(null)
    const [templateList, setTemplateList] = useState([])
    const [templateShow, setTemplateShow] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [addNew, setAddNew] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const modules = {
        toolbar: [
            [{ 'font': [] }, { 'size': [] }], // Font and size
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Headers
            [{ 'align': [] }], // Text alignment
            ['bold', 'italic', 'underline', 'strike'], // Formatting options
            [{ 'color': [] }, { 'background': [] }], // Font and background colors
            [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript/Superscript
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
            [{ 'indent': '-1' }, { 'indent': '+1' }], // Indentation
            [{ 'direction': 'rtl' }], // Text direction (right-to-left)
            //   [{ 'lineheight': ['1', '1.5', '2', '2.5', '3'] }], // Line height
            ['blockquote', 'code-block'], // Blockquote and code block
            ['link', 'image', 'video'], // Links, images, videos
            ['clean'], // Remove formatting
        ],
    };

    const formats = [
        'font', 'size', 'header', 'align', 'bold', 'italic', 'underline', 'strike',
        'color', 'background', 'script', 'list', 'indent', 'direction', 'lineheight',
        'blockquote', 'code-block', 'link', 'image', 'video'
    ];

    const setInfoFun = (key, value) => {
        let temp = { ...info, [key]: value };
        setInfo(temp);
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1200);
    };

    const onSubmit = async (values) => {
        updateCarePlan(values)
    };

    function updateCarePlan(values) {
        setIsSubmitting(true)
        visitUpdate({ carePlan: { ...values } }, visitDetails?._id)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    updateState(res.data)
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.carePlan = info?.carePlan;
            updateCarePlan(values)

        }
    }

    const onSubmitCreate = async () => {
        setIsLoading(true)
        await post(`doctor/care-plan/template`, {
            doctor: visitDetails?.doctor,
            title: selectedTemplate?.title || "",
            startDate: "",
            endDate: "",
            details: selectedTemplate?.details || ""
        })
            .then((res) => {
                if (res?.status == 200) {
                    setSelectedTemplate(res?.data)
                    getCarePlanTemplate(visitDetails?.doctor)
                    setAddNew(false)
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsLoading(false));
    }

    const updateTemplate = async (templateId, key, value) => {
        clearTimeout(timeoutIdRef.current);
        let payload = {
            doctor: visitDetails?.doctor,
        }
        if (key == "details") {
            payload.details = value || ""
        }
        else payload.title = value || ""

        timeoutIdRef.current = setTimeout(async () => {
            await put(`doctor/care-plan/${templateId}`, payload)
                .then((res) => {
                    if (res?.status == 200) {

                    }

                })
                .catch((error) => {
                    console.log(error);
                });
        }, 1000);
    }

    const deleteTemplate = async (templateId) => {
        setIsLoading(true)
        await delWithBody(`doctor/care-plan/template/${templateId}`, {
            doctorId: visitDetails?.doctor
        })
            .then((res) => {
                if (res?.status == 200) {
                    getCarePlanTemplate(visitDetails?.doctor)
                }

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => setIsLoading(false));
    }

    const setOptionFun = (value) => {
        timeoutIdRef.current = setTimeout(async () => {
            await get(`doctor/care-plan/list/${visitDetails?.doctor}?search=${value?.target?.value}&per_page=30&current_page=1`)
                .then((res) => {
                    if (res?.status == 200) {
                        setTemplateList(res?.data?.carePlanTemplates)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }, 1000);
    }

    const getCarePlanTemplate = (docId) => {
        carePlanTemplates(docId)
            .then((res) => {
                if (res?.status == 200) {
                    setTemplateList(res?.data?.carePlanTemplates)
                }
            })
            .catch((error) => {

            });
    }

    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);



    useMemo(() => {
        if (visitDetails) {
            setInfo({ details: visitDetails?.carePlan?.details });
            getCarePlanTemplate(visitDetails?.doctor)
        }
    }, [visitDetails])


    const handleSubmit = async (value) => {
        setIsLoading(true)
        setCarePlanTemplate({ visitId: visitDetails?._id, templateId: value?._id })
            .then((res) => {
                if (res?.status == 200) {
                    console.log(res?.data)
                    updateState(res?.data)
                }
            })
            .catch((error) => {

            })
            .finally(() => setIsLoading(false));;
    }

    return (
        <div className='care-plan-section'>
            {/* <div className='p-0 m-0 d-flex'>
                <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
            </div> */}
            <div className='row'>
                <div className='col-md-4'>
                    <label style={{ fontSize: "14px" }} htmlFor="exampleInputEmail1" className="form-label mt-2">
                        Care Plan
                    </label>
                </div>
                <div className='col-md-6' style={{ zIndex: "999" }}>
                    <SearchSelect setAddNew={setAddNew} uniqKey={3} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList} placeHolder='Search templates'></SearchSelect>
                </div>

                <div className='col-md-2'>
                    <button onClick={() => setTemplateShow(true)} className='btn btn-success py-0 my-0' style={{ fontSize: "13px" }}>Add template</button>
                </div>
            </div>
            {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
                {(formik) => (
                    <div className="">
                        <Form
                            action=""
                            id=""
                            className="custom-form input-section position-relative"
                            encType="multipart/form-data"
                        >
                            <div className="row" style={{position:"relative"}}>
                                <div className="col-6 col-md-12  mb-0 pb-0 ">
                                    <div className="">
                                        <ReactQuill formats={formats} modules={modules} placeholder='care plan' style={{ height: "400px" }} theme="snow" value={info?.details || ""} onChange={(content) => { setInfoFun('details', content) }} />
                                        {isLoading&&<div style={{position:"absolute",left:"50%", top:"50%"}}><UniversalLoaderForComponent></UniversalLoaderForComponent></div>}
                                    </div>
                                </div>

                            </div>

                        </Form>
                    </div>
                )}
            </Formik>}

            <CustomModal
                size="xl"
                show={templateShow}
                isHeaderHide={true}
                setShow={setTemplateShow}
                autoClose={false}
            >
                <div style={{ background: "#15AA81", borderRadius: "3px 3px 0px 0px" }} className="d-flex justify-content-end ml-auto mt-0 pt-0 px-1" onClick={() => {
                    setSelectedTemplate({ title: "", details: "" })
                    setAddNew(false)
                    setTemplateShow(false)
                }}>
                    <small style={{ color: "white", fontWeight: "700", cursor: "pointer" }} className="close-button" >
                        Close
                    </small>
                </div>
                <section className='care-plan-template-section d-flex pt-3' style={{overflowY:"scroll"}}>
                    <div className='name-section' style={{overflowY:"scroll"}}>
                        <NameSectionCarePlan isLoading={isLoading} setOptionFun={setOptionFun} deleteTemplate={deleteTemplate} setAddNew={setAddNew} list={templateList} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} doctorId={visitDetails?.doctor}></NameSectionCarePlan>
                    </div>
                    <div className='prescription-section'>
                        {(addNew) && <Formik initialValues={{}} onSubmit={onSubmitCreate}>
                            {(formik) => (
                                <div className="">
                                    <Form
                                        action=""
                                        id=""
                                        className=" input-section position-relative"
                                        encType="multipart/form-data"
                                    >
                                        <div className="row  ">
                                            <div className="col-6 col-md-6  mb-2 pb-0 ">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    required
                                                    className="form-control input"
                                                    autoComplete="off"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={selectedTemplate?.title || ""}
                                                    placeholder="Enter title"
                                                    onChange={(e) => {
                                                        setSelectedTemplate({ ...selectedTemplate, title: e.target.value })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 col-md-12  mb-0 pb-0">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Details<span className="text-danger">*</span>
                                                </label>
                                                <div className="" style={{ height: "300px" }}>
                                                    <ReactQuill formats={formats} modules={modules} placeholder='care plan' style={{ height: "200px" }} theme="snow" value={selectedTemplate?.details || ""} onChange={(content) => { setSelectedTemplate({ ...selectedTemplate, details: content }) }} />
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12  mb-0 pb-0 text-center mb-2">
                                                {addNew && <button className='btn btn-success py-0 my-0'>Create template</button>}
                                            </div>
                                        </div>

                                    </Form>

                                </div>
                            )}
                        </Formik>}

                        {selectedTemplate?._id && <Formik initialValues={{}} onSubmit={{}}>
                            {(formik) => (
                                <div className="">
                                    <Form
                                        action=""
                                        id=""
                                        className=" input-section position-relative"
                                        encType="multipart/form-data"
                                    >
                                        <div className="row  ">
                                            <div className="col-6 col-md-6  mb-2 pb-0 ">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Name<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    required
                                                    className="form-control input"
                                                    autoComplete="off"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    value={selectedTemplate?.title || ""}
                                                    placeholder="Enter title"
                                                    onChange={(e) => {
                                                        setSelectedTemplate({ ...selectedTemplate, title: e.target.value })
                                                        updateTemplate(selectedTemplate?._id, 'title', e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 col-md-12  mb-0 pb-0">
                                                <label htmlFor="exampleInputEmail1" className="form-label">
                                                    Details<span className="text-danger">*</span>
                                                </label>
                                                <div className="" style={{ height: "300px" }}>
                                                    <ReactQuill formats={formats} modules={modules} placeholder='care plan' style={{ height: "200px" }} theme="snow" value={selectedTemplate?.details || ""} onChange={(content) => {
                                                        setSelectedTemplate({ ...selectedTemplate, details: content })
                                                        updateTemplate(selectedTemplate?._id, 'details', content)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>

                                    </Form>

                                </div>
                            )}
                        </Formik>}

                    </div>
                </section>
            </CustomModal>
        </div>
    )
}

export default CarePlan

import React, { useEffect, useMemo, useState } from 'react'
import "./surgical-histories.scss"
import { useDispatch, useSelector } from 'react-redux';
import { patientAddSubDocument, patientRemoveSubDocument, patientSurgeriesAdd } from '../../../service/api/patient-api';
import { getPatientSurgeryList, getSurgeryList } from '../../../store/slices/visitSlice';
import UniversalLoaderForComponent from '../../loader/universalLoaderForComponent';
import { post } from '../../../utils/fetchAPI';

const SurgicalHistories = ({ patientId, visitDetails, patientDetails, updateState }) => {
  let { isLoading, surgeryList } = useSelector(
    (state) => state.visit
  );
  const [patientSurgeryList, setPatientSurgeryList] = useState([])
  const [onlySelectedShow, setOnlySelectedShow] = useState(false)
  const [searchQuery, setSearchQuery] = useState()
  const [info, setInfo] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (patientDetails?.surgicalHistories)
      setPatientSurgeryList(patientDetails?.surgicalHistories)
  }, [updateState,patientDetails])

  const surgeryAddOrRemoveFun = async (surgery, event) => {
    if (event.target.checked == true) {
      setInfo((prev) => ({ ...prev, [surgery?._id]: surgery?._id }));
      onChangeSubmit(surgery?._id, surgery?.name)
    }
    else {
      let temp = { ...info }
      delete temp[surgery?._id];
      setInfo(temp)
      onRemove(surgery?._id)
    }

  }

  const onChangeSubmit = async (key, name) => {
    await patientAddSubDocument("surgicalHistories", { surgery: key, name: name }, (visitDetails?.patient || patientId))
      .then((res) => {
        if (res?.status === 200) {
          updateState(res?.data)
          //setPatientSurgeryList(res?.data?.patientDetails?.surgicalHistories)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const onRemove = async (key) => {
    await patientRemoveSubDocument("surgicalHistories", { surgery: key }, (visitDetails?.patient || patientId))
      .then((res) => {
        if (res.status === 200) {
          updateState(res?.data)
          //setPatientSurgeryList(res?.data?.patientDetails?.surgicalHistories)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addNew = async () => {
    setLoading(true)
    await post('/surgery/create', { doctor: visitDetails?.doctor, name: searchQuery })
      .then((res) => {
        if (res.status == 200) {
          dispatch(getSurgeryList())
          setSearchQuery(searchQuery)
        }
      }).finally(() => {
        setLoading(false)
      })
  }

  let listOfSurgeries = useMemo(() => {
    // if (patientSurgeryList && surgeryList) {

    //   patientSurgeryList?.map((surgery) => {
    //     let element = document.getElementById(`check-${surgery?.surgery}`)
    //     if (element)
    //       element.checked = true
    //   })

    // }
    // if (onlySelectedShow && surgeryList) {
    //   let filterList = surgeryList.filter(surgery => patientSurgeryList?.surgeries?.includes(surgery._id))
    //   return filterList
    // }
    let filterList = surgeryList || []
    if (searchQuery && surgeryList) {
      let filterList = surgeryList.filter(surgery => surgery?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
      return filterList
    }
    if (patientSurgeryList) {
      patientSurgeryList.map((data) => {
        setInfo((prev) => ({ ...prev, [data?.surgery]: data?.surgery }));
      })
    }
    return filterList
  }, [surgeryList, onlySelectedShow, patientSurgeryList, searchQuery])

  return (
    <div className='surgical-history-list'>
      <div className='surgical-history-search-section border-bottom '>
        {/* <div className='d-flex'>
          <label className="switch">
            <input id="surgery-switch" type="checkbox" onChange={() => { setOnlySelectedShow(!onlySelectedShow) }} />
            <span className="slider round"></span>
          </label>
          <label className='mt-0  pt-0 ml-1' htmlFor="surgery-switch">Show selected</label>
        </div> */}
        <div className='header mb-0 pb-0'>
          <p className='mb-0 pb-0'>Surgery history</p>
        </div>
        <input
          className="appointment-search mb-0 pb-0"
          placeholder="Search surgery"
          type="text"
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        ></input>
      </div>
      <div className='surgical-scroll-section'>
        <div className='surgical-list'>
          {
            listOfSurgeries?.map((surgery, index) => {
              return (<div className={`individual-surgery d-flex py-1`} key={index} >
                {/* <input onChange={(event) => { surgeryAddOrRemoveFun(surgery, event.target.checked) }} type="checkbox" id={"check-" + surgery?._id} name={"check-" + surgery?._id} checked={patientSurgeryList?.surgeries?.includes(surgery?._id) ? true : ""} /> */}
                <input onChange={(e) => { surgeryAddOrRemoveFun(surgery, e) }} checked={info?.hasOwnProperty(surgery?._id)} type="checkbox" id={"check-" + surgery?._id} name={"check-" + surgery?._id} />
                <label className='ml-2 align-content-center justify-content-center my-auto' htmlFor={"check-" + surgery?._id}> {surgery?.name}</label><br />
              </div>)
            })
          }
        </div>
        <div>
          {listOfSurgeries?.length == 0 && !loading && <div className='d-flex mx-1'>
            <p onClick={() => { addNew() }} className='px-2 mt-1' style={{ backgroundColor: "#14A67D", color: "white", cursor: "pointer", fontSize: "13px" }}>({searchQuery}) Add</p></div>}
        </div>
        {loading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
      </div>
    </div>
  )
}

export default SurgicalHistories

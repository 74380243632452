// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import "./diagnosis.scss"
// import { Form, Formik } from 'formik';
// import { visitUpdate } from '../../../service/api/visit';
// import { Icd11 } from '../../common/icd11/icd11';

// const Diagnosis = ({ visitDetails = {}, updateState }) => {
//     const [isSubmitting, setIsSubmitting] = useState(false)
//     const [info, setInfo] = useState({});
//     const timeoutIdRef = useRef(null)
//     const setInfoFun = (key, value) => {
//         // let temp = { ...info };
//         // setInfo({ ...temp, [key]: value });
//         clearTimeout(timeoutIdRef.current);
//         let temp = { ...info, [key]: value };
//         setInfo(temp);

//         timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1000);
//     };



//     const onSubmit = async (values) => {
//         // values.provisionalDx = info.provisionalDx;
//         // values.differentialDx = info.differentialDx;
//         // values.patient = patientId
//         updateComplaint(values)
//     };

//     const setProvisionalDiagnosis = async (value) => {
//         let values = {}
//         values.provisionalDx = info?.provisionalDx + (info?.provisionalDx ? "\n" : "") + value;
//         values.differentialDx = info.differentialDx;
//         setInfo(values)
//         await updateComplaint(values)
//     }

//     const setDifferentialDiagnosis = async (value) => {
//         let values = {}
//         values.provisionalDx = info?.provisionalDx;
//         values.differentialDx = info?.differentialDx + (info?.differentialDx ? "\n" : "") + value;
//         setInfo(values)
//         await updateComplaint(values)
//     }


//     function updateComplaint(values) {
//         setIsSubmitting(true)
//         visitUpdate(values, visitDetails?._id)
//             .then((res) => {
//                 if (res?.status == 200) {
//                     setIsSubmitting(false);
//                     updateState(res.data)
//                 }
//             })
//             .catch((error) => {
//                 setIsSubmitting(false);
//             });
//     }

//     const handleKeyDown = (event) => {
//         if ((event.metaKey || event.ctrlKey) && event.key === 's') {
//             event.preventDefault();
//             let values = {}
//             values.provisionalDx = info.provisionalDx;
//             values.differentialDx = info.differentialDx;
//             updateComplaint(values)

//         }
//     }
//     useEffect(() => {
//         if (info)
//             document.addEventListener('keydown', handleKeyDown);
//         return () => {
//             document.removeEventListener('keydown', handleKeyDown);
//         };
//     }, [info]);




//     useMemo(() => {
//         if (visitDetails) {
//             setInfo({ provisionalDx: visitDetails?.provisionalDx || "", differentialDx: visitDetails?.differentialDx || "" });
//         }
//     }, [visitDetails])

//     return (
//         <div className='diagnosis-section'>
//             {/* <div className='p-0 m-0 d-flex'>
//                 <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
//             </div> */}
//             {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
//                 {(formik) => (
//                     <div className="">
//                         <Form
//                             action=""
//                             id=""
//                             className="custom-form input-section position-relative"
//                             encType="multipart/form-data"
//                         >
//                             <div className="row  ">

//                                 <div className="col-6 col-md-12  mb-0 pb-0 ">
//                                     <Icd11 placeHolder="Search provisional diagnosis" getValue={setProvisionalDiagnosis}></Icd11>
//                                     <label htmlFor="exampleInputEmail1" className="form-label">
//                                         Provisional Diagnosis
//                                     </label>
//                                     <div className="d-flex">
//                                         {" "}
//                                         <textarea
//                                             className="form-control  w-100"
//                                             autoComplete="off"
//                                             type="text"
//                                             rows={8}
//                                             placeholder="Provisional Diagnosis"
//                                             value={info?.provisionalDx || ""}
//                                             onChange={(e) => {
//                                                 setInfoFun("provisionalDx", e.target.value);
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className="col-6 col-md-12  mb-0 pb-0 mt-5">
//                                     <Icd11 placeHolder="Search differential diagnosis" getValue={setDifferentialDiagnosis}></Icd11>
//                                     <label htmlFor="exampleInputEmail1" className="form-label">
//                                         Differential Diagnosis
//                                     </label>
//                                     <div className="d-flex">
//                                         {" "}
//                                         <textarea
//                                             className="form-control  w-100"
//                                             autoComplete="off"
//                                             type="text"
//                                             rows={8}
//                                             placeholder="Differential Diagnosis"
//                                             value={info?.differentialDx || ""}
//                                             onChange={(e) => {
//                                                 setInfoFun("differentialDx", e.target.value);
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                                 {/* <div className="col-12  text-center mt-3 d-flex">
//                                     <button
//                                         type="submit"
//                                         className="btn submit-button  mx-auto w-25"
//                                         onClick={() => {
//                                         }}
//                                     >
//                                         Save
//                                         {isSubmitting && (
//                                             <span
//                                                 className="spinner-border spinner-border-sm"
//                                                 role="status"
//                                                 aria-hidden="true"
//                                             ></span>
//                                         )}
//                                     </button>
//                                 </div> */}
//                             </div>
//                         </Form>
//                     </div>
//                 )}
//             </Formik>}

//             {/* <table className="table table-sm table-hover">
//                 <thead>
//                     <tr>
//                         <th scope="col">Date</th>
//                         <th scope="col">Reason For Visit</th>
//                         <th scope="col">History Of Preset Illness</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {noComplaint ? (
//                         <tr className="mx-2"><td>No previous history available</td></tr>
//                     ) : (
//                         complaints?.complaints?.map((complaint, index) => {
//                             return (
//                                 <tr key={index}>
//                                     <td>{complaint?.createdAt?.slice(0, 10)}</td>
//                                     <td>{complaint?.reasonForVisit}</td>
//                                     <td>{complaint?.historyOfPresetIllness}</td>
//                                 </tr>
//                             );
//                         })
//                     )}
//                     {isLoading && <tr><td className="mx-2">loading...</td></tr>}
//                 </tbody>
//             </table> */}
//         </div>
//     )
// }

// export default Diagnosis


import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./diagnosis.scss"
import { Form, Formik } from 'formik';
import { visitUpdate } from '../../../service/api/visit';
import CustomModal from '../../common/modal/modal';
import { put, post, get, del } from "../../../utils/fetchAPI";
import { showSuccessToast } from '../../../utils/toaster-alert';
import { Icd11 } from '../../common/icd11/icd11';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import CreatableSearchSelect from '../../common/updated-input/createble-search-select/creatable-search-select';

const Diagnosis = ({ visitDetails = {}, updateState }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const [showAdd, setShowAdd] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [templateInfo, setTemplateInfo] = useState({})
    const [templateList, setTemplateList] = useState([])
    const [presentIllnessList, setPresentIllnessList] = useState([])
    const timeoutIdRef = useRef(null)
    const [showTemplates, setShowTemplates] = useState(false)

    const [showPresentIllness, setShowPresentIllness] = useState(false)
    const [showPresenetDelete, setShowPresenetDelete] = useState(false)

    useEffect(() => {
        if (visitDetails?.doctor) {
            getProvisionalDxList(visitDetails?.doctor)
            getDifferentialDxList(visitDetails?.doctor)
        }
    }, [visitDetails?.doctor])


    // Provisional Dx 
    const setProvisionalDxFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getProvisionalDxList(visitDetails?.doctor, event?.target?.value)) }, 800)

    }
    const handleProvisionalDxAddSubmit = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.provisionalDx || "") + (data?.provisionalDx ? "\n" : "") + (value?.provisionalDx || ""))
        updateComplaint({ provisionalDx: updatedReasonForVisit, differentialDx: data?.differentialDx })
    }
    const handleCreateProvisionalDx = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.provisionalDx || "") + (data?.provisionalDx ? "\n" : "") + (value || ""))
        updateComplaint({ provisionalDx: updatedReasonForVisit, differentialDx: data?.differentialDx })
        createNewProvisionalDx(value)
    }
    // -----------------

    // differentialDx  
    const setDifferentialDxOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getDifferentialDxList(visitDetails?.doctor, event?.target?.value)) }, 500)

    }
    const handleDifferentialDxAddSubmit = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.differentialDx || "") + (data?.differentialDx ? "\n" : "") + (value?.differentialDx || ""))
        updateComplaint({ provisionalDx: data?.provisionalDx, differentialDx: updatedPresentIlness })
    }
    const handleCreatePresentIllness = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.differentialDx || "") + (data?.differentialDx ? "\n" : "") + (value || ""))
        updateComplaint({ provisionalDx: data?.provisionalDx, differentialDx: updatedPresentIlness })
        createDifferentialDxTemplate(value)
    }
    // -----------------


    const setInfoFun = (key, value, submit = false) => {
        // let temp = { ...info };
        // setInfo({ ...temp, [key]: value });
        let temp = { ...info, [key]: value };
        setInfo(temp);
        if (submit) {
            onSubmit(temp)
        }
        // clearTimeout(timeoutIdRef.current);
        // timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 500);
    };


    const onSubmit = async (values) => {
        updateComplaint(values)
    };


    function updateComplaint(values) {
        setIsSubmitting(true)
        visitUpdate(values, visitDetails?._id)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    updateState(res.data)
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    }

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.provisionalDx = info?.provisionalDx;
            values.differentialDx = info?.differentialDx;
            updateComplaint(values)

        }
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);


    const createNewProvisionalDx = async (value = info?.provisionalDx) => {
        await post(`/doctor/provisionalDx`, { provisionalDx: value })
            .then((res) => {
                if (res?.status == 200) {
                    getProvisionalDxList(visitDetails?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const createDifferentialDxTemplate = async (value = info?.differentialDx) => {

        await post(`/doctor/differentialDx`, { differentialDx: value })
            .then((res) => {
                if (res?.status == 200) {
                    getDifferentialDxList(visitDetails?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const getProvisionalDxList = async (doctorId, search = "") => {

        await get(`/doctor/provisionalDx/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setTemplateList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }
    const getDifferentialDxList = async (doctorId, search = "") => {

        await get(`/doctor/differentialDx/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setPresentIllnessList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updateTemplate = async (type) => {
        let { differentialDx, ...payload } = templateInfo
        await put(`/doctor/${type}/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getProvisionalDxList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowAdd(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updatePresnetIllness = async () => {
        let { provisionalDx, ...payload } = templateInfo
        await put(`/doctor/differentialDx/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getDifferentialDxList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowPresentIllness(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deleteTemplate = async (type, id) => {
        await del(`/doctor/${type}/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getProvisionalDxList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deletePresentIllness = async (id) => {
        await del(`/doctor/differentialDx/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getDifferentialDxList(visitDetails?.doctor)
                    setTemplateInfo({})
                    setShowPresenetDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }



    useMemo(() => {
        if (visitDetails) {
            setInfo({ provisionalDx: visitDetails?.provisionalDx || "", differentialDx: visitDetails?.differentialDx || "" });
        }
    }, [visitDetails])







    return (
        <div className='complaint'>
            <div className='complaint-section'>
                {/* <div className='p-0 m-0 d-flex'>
                <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
            </div> */}
                {visitDetails && <Formik initialValues={{}} onSubmit={onSubmit}>
                    {(formik) => (
                        <div className="">
                            <Form
                                action=""
                                id=""
                                className="custom-form input-section position-relative"
                                encType="multipart/form-data"
                            >

                                <div className="row  ">
                                    <div className="col-6 col-md-12  mb-0 pb-0 ">
                                        {/* <Icd11 placeHolder="Search reason for visit" getValue={setReasonForvisit}></Icd11> */}
                                        <CreatableSearchSelect options={templateList} setOptionFun={setProvisionalDxFun} handleSubmit={handleProvisionalDxAddSubmit}
                                            handleCreate={handleCreateProvisionalDx}
                                        ></CreatableSearchSelect>
                                        {/* <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            Reason For Visit
                                        </label> */}
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                Provisional Diagnosis
                                            </label>
                                            <div className='d-flex'>
                                                <p onClick={() => {
                                                    createNewProvisionalDx()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        {/* <p onClick={() => {
                                            createNewProvisionalDx()
                                        }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p> */}
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={5}
                                                placeholder="Reason For Visit"
                                                value={info?.provisionalDx || ""}
                                                onChange={(e) => {
                                                    setInfoFun("provisionalDx", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("provisionalDx", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 mt-5 pt-4">

                                        {/* <Icd11 placeHolder="Search history of present illness" getValue={setHistoryOfPresentIllness}></Icd11> */}
                                        {/* <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            History Of Present Illness
                                        </label> */}
                                        <CreatableSearchSelect options={presentIllnessList} setOptionFun={setDifferentialDxOptionFun} handleSubmit={handleDifferentialDxAddSubmit}
                                            handleCreate={handleCreatePresentIllness}
                                        ></CreatableSearchSelect>
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                Differential Diagnosis
                                            </label>
                                            <div className='d-flex'>
                                                <p onClick={() => {
                                                    createDifferentialDxTemplate()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={5}
                                                placeholder="History Of Preset Illness"
                                                value={info?.differentialDx || ""}
                                                onChange={(e) => {
                                                    setInfoFun("differentialDx", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("differentialDx", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>}



                <CustomModal
                    size="md"
                    show={showAdd}
                    isHeaderHide={true}
                >
                    <div className='mt-0  create-template px-2 py-2'>

                        <label className='mt-0 pt-0 d-block'>Reason For Visit</label>
                        <textarea className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.provisionalDx} onChange={(e) => {
                            setTemplateInfo({ provisionalDx: e.target.value, provisionalDxId: templateInfo?.provisionalDxId, doctorId: visitDetails?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updateTemplate("provisionalDx")
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowAdd(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="sm"
                    show={showDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deleteTemplate("provisionalDx", templateInfo?.provisionalDxId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="md"
                    show={showPresentIllness}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <label className='mt-0 pt-0 d-block'>History of present illness</label>
                        <textarea key={12} className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.differentialDx} onChange={(e) => {
                            setTemplateInfo({ differentialDx: e.target.value, differentialDxId: templateInfo?.differentialDxId, doctorId: visitDetails?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updatePresnetIllness()
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowPresentIllness(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="sm"
                    show={showPresenetDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deletePresentIllness(templateInfo?.differentialDxId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowPresenetDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="xl"
                    show={showTemplates}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='p-4' >
                        <div className='d-flex justify-content-end mb-1'>
                            <button style={{ background: "red", color: "white" }} onClick={() => setShowTemplates(false)} className='btn py-0'>Close</button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflowX: "scroll" }}>
                            <table className="table table-sm table-hover border" >
                                <div className='d-flex'>
                                    <div className='p-2' style={{ width: "50%", borderRight: "1px solid black", minHeight: "30vh" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}> Provisional Dx</p>
                                        <div>
                                            {templateList?.length > 0 ? <div>
                                                {templateList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.provisionalDx}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                                <button onClick={() => {
                                                                    setTemplateInfo({ provisionalDx: complaint?.provisionalDx || "", provisionalDxId: complaint?._id, doctorId: visitDetails?.doctor })
                                                                    setShowAdd(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ provisionalDxId: complaint?._id })
                                                                    setShowDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No template found</small>}
                                        </div>
                                    </div>
                                    <div className='p-2' style={{ width: "50%" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}>Differential Dx</p>
                                        <div>
                                            {presentIllnessList?.length > 0 ? <div>
                                                {presentIllnessList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.differentialDx}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                                <button onClick={() => {
                                                                    setTemplateInfo({ differentialDx: complaint?.differentialDx || "", differentialDxId: complaint?._id, doctorId: visitDetails?.doctor })
                                                                    setShowPresentIllness(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ differentialDxId: complaint?._id })
                                                                    setShowPresenetDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No template found</small>}
                                        </div>
                                    </div>
                                </div>
                            </table>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>

    )
}

export default Diagnosis


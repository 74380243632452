import React, { useEffect, useMemo, useRef, useState } from 'react'
import './family-history.scss'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { customStyles } from '../../../utils/styles/styles';
import { patientAddSubDocument, patientFamilyHistoryAdd, patientRemoveSubDocument, patientUpdateSubDocument } from '../../../service/api/patient-api';
import { getDiseaseList, getPatientFamilyHistoryList, resetFamilyHistory } from '../../../store/slices/visitSlice';
import { LocalStorageService } from '../../../utils/local-storage';
import UniversalLoaderForComponent from '../../loader/universalLoaderForComponent';
import { post } from '../../../utils/fetchAPI';

const FamilyHistory = ({ patientId, visitDetails, patientDetails, updateState }) => {

    const [searchQuery, setSearchQuery] = useState()
    let { diseaseList, } = useSelector(
        (state) => state.visit
    );
    const [info, setInfo] = useState({})
    const [onlySelectedShow, setOnlySelectedShow] = useState(false)
    const timeoutIdRef = useRef(null)
    const [patientFamilyHistory, setPatientFamilyHistory] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const options = [
        { value: 'Brother', label: 'Brother' },
        { value: 'Child', label: 'Child' },
        { value: 'Mother', label: 'Mother' },
        { value: 'Father', label: 'Father' },
        { value: 'Natural child', label: 'Natural child' },
        { value: 'Parent', label: 'Parent' },
        { value: 'Sibling', label: 'Sibling' },
        { value: 'Sister', label: 'Sister' },
        { value: 'Extended family', label: 'Extended family' },
        { value: 'Grandchild', label: 'Grandchild' },
        { value: 'Grandparent', label: 'Grandparent' },
        { value: 'Other', label: 'Other' },
    ]

    useEffect(() => {
        if (patientDetails?.familyHistories) {
            setPatientFamilyHistory(patientDetails?.familyHistories)
        }
    }, [updateState,patientDetails])
    const setInfoFun = (key, value, type, name) => {
        let temp = { ...info };
        let localVal = LocalStorageService.get('family-history')
        let Check = document?.getElementById(`disease-${key}`);
        if (type === "check" && key in temp) {
            delete temp[key];
            setInfo(temp)
            LocalStorageService.set("family-history", { info: temp || {} })
            setOnlySelectedShow(false)
            onRemove(key)
        }
        else {
            if (Check) Check.checked = true
            if (key in temp) {
                onUpdate(key, value)
            }
            else {
                onChangeSubmit(key, value, name)
            }
            LocalStorageService.set("family-history", { info: { ...temp, [key]: value } || {} })
            setInfo({ ...temp, [key]: value });

        }

    };

    const onChangeSubmit = async (key, value, name) => {

        await patientAddSubDocument("familyHistories", { disease: key, name: name, from: value }, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res?.status === 200) {
                    updateState(res?.data)
                    //setPatientFamilyHistory(res?.data?.patientDetails?.familyHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const onRemove = async (key) => {
        await patientRemoveSubDocument("familyHistories", { disease: key }, (visitDetails?.patient || patientId))
            .then((res) => {
                if (res.status === 200) {
                    updateState(res?.data)
                    //setPatientFamilyHistory(res?.data?.patientDetails?.familyHistories)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onUpdate = async (key, value) => {
        const body = {
            payload: {
                "familyHistories.$.from": value
            },
            filterKey: "familyHistories.disease",
            filterKeyValue: key
        }
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await patientUpdateSubDocument(body, (visitDetails?.patient || patientId))
                .then(res => {
                    if (res.status === 200) {
                        updateState(res?.data)
                        //setPatientFamilyHistory(res?.data?.patientDetails?.familyHistories)
                    }
                }).catch((error) => {
                    console.log(error);
                });

        }, 500);

    }

    const handleSubmit = async (val) => {
        // let updatedInfo = val?.info?.length ? val?.info : info;
        // let diseases = Object.keys(updatedInfo).map((key) => {
        //     return { disease: key, from: info[key] };
        // });
        // const textbox = document?.getElementById('family-others-text-area')
        // const payLoad = { diseases: diseases, others: val?.note?.length ? val?.note : textbox.value }
        // setIsSubmitting(true)
        // let checkSwitch = document?.getElementById('family-history-switch')

        // await patientFamilyHistoryAdd(payLoad, (visitDetails?.patient || patientId))
        //     .then((res) => {
        //         if (res.status === 200) {
        //             dispatch(getPatientFamilyHistoryList((visitDetails?.patient || patientId)))
        //             setIsSubmitting(false)
        //             if (checkSwitch) {
        //                 checkSwitch.checked = false
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         setIsSubmitting(false)
        //     });
    }

    let listOfDisease = useMemo(() => {
        if (searchQuery && diseaseList) {
            let filterList = diseaseList.filter(disease => disease?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
            return filterList
        }
        if (patientFamilyHistory) {
            patientFamilyHistory.map((data) => {
                setInfo((prev) => ({ ...prev, [data?.disease]: data?.from }));
            })
        }

        return diseaseList

    }, [diseaseList, searchQuery, patientFamilyHistory])



    useMemo(() => {
        if (onlySelectedShow && listOfDisease) {
            let diseaseListNew = listOfDisease.filter(disease => info.hasOwnProperty(disease?._id))
            listOfDisease = diseaseListNew
        }
    }, [onlySelectedShow, listOfDisease, diseaseList,])


    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            const val = LocalStorageService.get('family-history')
        }
    };


    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);


    const addNew = async () => {
        setIsLoading(true)
        await post('/disease/create', { doctor: visitDetails?.doctor, name: searchQuery })
            .then((res) => {
                console.log("check test", res)
                if (res.status == 200) {
                    dispatch(getDiseaseList())
                    setSearchQuery(searchQuery)
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className='family-history-section'>
            <div className='family-history-section-header'>
                {/* <div className='header'>
                    <p onClick={() => { handleSubmit() }} className={`button ${isSubmitting ? "disable" : ""}`}>Save
                        {isSubmitting && (
                            <span
                                className="spinner-border spinner-border-sm ml-1"
                                role="status"
                                aria-hidden="true"
                            ></span>)}
                    </p>
                    <div className='d-flex ml-2 mr-1'>
                        <label className="switch">
                            <input id="family-history-switch" type="checkbox" onChange={() => { setOnlySelectedShow(!onlySelectedShow) }} />
                            <span className="slider round"></span>
                        </label>
                        <label className='mt-0  pt-0 ml-1' for="surgery-switch">Show selected family history</label>
                    </div>
                </div> */}
                <div className='header'>
                    <p>Family history</p>
                </div>
                <div className='family-history-search'>
                    <input
                        className="appointment-search"
                        placeholder="Search disease"
                        type="text"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    ></input>
                </div>
            </div>
            <div className='scroll-section'>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                    {listOfDisease?.map((disease, index) => {
                        return <>
                            <div key={index} className='individual-social-disease py-1'>
                                {/* checked={patientSurgeryList?.surgeries?.includes(surgery?._id) ? true : ""} */}
                                <div className='d-flex' onClick={() => { setInfoFun(disease?._id, "", "check", disease?.name) }}>
                                    <input checked={info.hasOwnProperty(disease?._id)} type="checkbox" id={`disease-${disease?._id}`} name={`disease-${disease?._id}`} />
                                    <p className='ml-2 align-content-center justify-content-center my-auto'> {disease?.name}</p>
                                </div>

                                <Select
                                    id={disease?._id}
                                    styles={customStyles}
                                    className="select-input pb-0 mb-0"
                                    options={options}
                                    placeholder="from"
                                    value={info[disease?._id] ? { label: info[disease?._id] } : ""}
                                    onChange={(value) => setInfoFun(disease?._id, value?.label, "input", disease?.name)}
                                />

                            </div>
                        </>
                    })}
                </div>
                <div>
                    {listOfDisease?.length == 0 && !isLoading && <div className='d-flex mx-1'>
                        <p onClick={() => { addNew() }} className='px-2 mt-1' style={{ backgroundColor: "#14A67D", color: "white", cursor: "pointer", fontSize: "13px" }}>({searchQuery}) Add</p></div>}
                </div>
                {isLoading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
            </div>

        </div>
    )
}

export default FamilyHistory

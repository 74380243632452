import { faCapsules, faChevronCircleLeft, faCircleXmark, faEnvelopeCircleCheck, faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import "./header.scss"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../common/modal/modal";
import { appointmentStatusChange } from "../../../service/api/appointment-api";
import PrescriptionPrint from "../../prescription/prescription-print/prescription-print";
import { getTemplateList } from "../../../store/slices/templateSlice";
import { mergeTemplateToPrescription } from "../../../service/api/prescription";
import SearchSelect from "../../common/updated-input/createble-search-select/search-select";
import SendPrescription from "../../prescription/send-prescription/send-prescription";
import { post, put } from "../../../utils/fetchAPI";

const Header = ({ setSelectedOption, isContentHide = false, visitDetails, patientId, backUrl, backBtnHide = false, fromPatient = false, updateState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const ref = useRef();
  const [showPrint, setShowPrint] = useState(false);
  const [showSentBox, setShowSentBox] = useState(false);
  let { isLoading, patientDetails } = useSelector((state) => state.patient);
  let { templateList } = useSelector((state) => state.template);
  const [templateName, setTemplateName] = useState("")
  const [templateModalShow, setTemplateModalShow] = useState(false)
  const [referralModal, setReferralModal] = useState(false)
  const [nextVisitModal, setNextVisitModal] = useState(false)
  const [nextVisitDate, setNextVisitDate] = useState(visitDetails?.followUpDate?.slice(0, 10) || "")
  const [nextVisitInstruction, setNextVisitInstruction] = useState(visitDetails?.followUpReason || '')
  const [referralInstruction, setReferralInstruction] = useState(visitDetails?.referral || '')
  const currentDate = new Date().toISOString()
  const [nextVisitDuration, setNextVisitDuration] = useState()
  const [nextVisitInput, setNextVisitInput] = useState()
  const [defaultReason, setDefaultReason] = useState({})
  const { selectedRole } = useSelector((state) => state.auth);
  const [defaultInstruction, setDefaultInstruction] = useState([])
  const [referralNotes, setReferralNotes] = useState([])

  // const defaultInstruction = [
  //   {
  //     id: 1,
  //     value: "ল্যাব পরীক্ষার রিপোর্ট নিয়ে আসবেন"
  //   },
  //   {
  //     id: 2,
  //     value: "যদি প্রয়োজন হয়"
  //   }
  // ]
  const nextVisitDurations = [
    {
      id: 1,
      value: '7 days',
      value_bn: ''
    },
    {
      id: 2,
      value: '15 days',
      value_bn: ''
    },
    {
      id: 3,
      value: '30 days',
      value_bn: ''
    },
    {
      id: 4,
      value: '60 days',
      value_bn: ''
    },
    {
      id: 5,
      value: '90 days',
      value_bn: ''
    },
    {
      id: 6,
      value: '4 months',
      value_bn: ''
    },
    {
      id: 7,
      value: '5 months',
      value_bn: ''
    },
    {
      id: 8,
      value: '6 months',
      value_bn: ''
    },
  ]

  useEffect(() => {
    // dispatch(getPatientDetails(patientId));
    if (visitDetails?.doctor) {
      let templatePayload = {
        doctorId: visitDetails?.doctor,
        search: ""
      }
      dispatch(getTemplateList(templatePayload))
    }
  }, [visitDetails?.doctor]);
  const setStatusToCompleteFun = async () => {
    await appointmentStatusChange(visitDetails.appointment, {
      status: 'completed',
    })
      .then((res) => {
        if (res.status === 200) {
          navigate(backUrl)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const backToListFun = () => {
    navigate(backUrl)
  }

  const setOptionFun = (event) => {
    // console.log(event.target.value)
    event.preventDefault()
    let templatePayload = {
      doctorId: visitDetails?.doctor,
      search: event.target.value
    }
    dispatch(getTemplateList(templatePayload))
  }

  const handleSubmit = async (value) => {
    const payLoad = {
      appointmentId: visitDetails?.appointment,
      doctorId: visitDetails?.doctor,
      templateId: value?._id
    }
    await mergeTemplateToPrescription(payLoad, visitDetails._id)
      .then((res) => {
        if (res.status == 200) {
          if (updateState) {
            updateState(res.data)
          }
          //TODO update state
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally()
  }

  const createNewTemplate = async () => {
    const payLoad = {
      name: templateName,
      doctor: visitDetails?.doctor,
    }
    await post(`/visits/save-template/${visitDetails?._id}`, payLoad)
      .then((res) => {
        if (res.status == 200) {
          if (updateState) {
            updateState({ ...visitDetails, template: res?.data?._id })
            setTemplateModalShow(!templateModalShow)
            let templatePayload = {
              doctorId: visitDetails?.doctor,
              search: ""
            }
            dispatch(getTemplateList(templatePayload))
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally()
  }

  // const previousMedicineAdd = async () => {
  //   // /visit/add-prev-med/:visitId
  //   await post(`visit/add-prev-med/${visitDetails?._id}`)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         if (res?.data?.errors) {
  //           showErrorToast(res?.data?.errors[0]?.msg)
  //         } else {
  //           showSuccessToast("Previous visit medicine added to current prescription")
  //           updateState(res.data)
  //         }
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally()
  // }

  const nextVisitDateSet = (date, type) => {
    let currentDate = new Date()
    if (type == "input") {
      let dateInput = new Date(date)
      setNextVisitDate(date?.slice(0, 10))
      setNextVisitInput(dateInput.getDate() - currentDate.getDate())
    }
    else {
      let [value, valueType] = date?.split(" ")
      if (valueType == 'days') {
        currentDate.setDate(currentDate.getDate() + +value)
        setNextVisitDate(currentDate?.toISOString())
      }
      else if (valueType == 'months') {
        let currentMonth = currentDate.getMonth() + 1
        currentDate.setMonth((currentMonth + +value))
        setNextVisitDate(currentDate?.toISOString())
      }
      else {
        currentDate.setDate(currentDate.getDate() + +value)
        setNextVisitDate(currentDate?.toISOString())
      }
    }
  }

  const handleNextVisitSubmit = async () => {
    await put(`/visit/${visitDetails?._id}`, { followUpDate: nextVisitDate, followUpReason: nextVisitInstruction })
      .then((res) => {
        if (res.status === 200) {
          updateState(res?.data)
          setNextVisitModal(false)
        }
      })
      .catch((e) => { })
      .finally(() => { })
  }

  const handleReferral = async () => {
    await put(`/visit/${visitDetails?._id}`, { referral: referralInstruction })
      .then((res) => {
        if (res.status === 200) {
          updateState(res?.data)
          setReferralModal(false)
        }
      })
      .catch((e) => { })
      .finally(() => { })
  }

  const checkDefaultAndAdd = (e, value) => {
    if (e) {
      setNextVisitInstruction(value?.value)
      setDefaultReason(value)
    }
    else {
      setNextVisitInstruction("")
      setDefaultReason({})
    }
  }
  console.log("")

  useMemo(() => {
    if (selectedRole?.config?.nextVisitInstructions) {
      let data = selectedRole?.config?.nextVisitInstructions?.split("\n")
      let instructions = []

      if (data?.length > 0) {
        data?.map((d, index) => {
          instructions.push({ id: index + 1, value: d })
        })
      }
      setDefaultInstruction(instructions)
    }
  }, [selectedRole?.config?.nextVisitInstructions])

  useMemo(() => {
    if (visitDetails?.referral) setReferralInstruction(visitDetails?.referral || '')
    if (visitDetails?.followUpDate) setNextVisitDate(visitDetails?.followUpDate?.slice(0, 10) || '')
    if (visitDetails?.followUpReason) setNextVisitInstruction(visitDetails?.followUpReason || '')
  }, [visitDetails])

  useMemo(() => {
    if (selectedRole?.config?.referralNotes) {
      setReferralNotes(selectedRole?.config?.referralNotes?.trim()?.split('\n'))
    }

  }, [selectedRole])




  return (
    <div className="individual-patient-section-header flex-wrap d-flex align-content-center">
      {!backBtnHide && <div className="back-section">
        <p onClick={() => (setShowModal(true))} style={{ color: "#0fac81", cursor: "pointer" }}>
          <FontAwesomeIcon
            size="sm"
            className="mt-2 mr-2"
            style={{ marginTop: "5px", color: "#0fac81" }}
            icon={faChevronCircleLeft}
          />
          Back to list
        </p>
      </div>}

      {!isContentHide && <div className="patient-details-section pt-1">
        {!fromPatient && <p onClick={() => { setShow(true) }} className="px-2 complete-btn">Complete</p>}

        {!fromPatient && <p onClick={() => { setShowPrint(true) }} className="px-2 mx-2 complete-btn">Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}

        {!fromPatient && <p onClick={() => { setShowSentBox(true) }} className="px-2 mx-2 mr-3 complete-btn">Sent Prescription <FontAwesomeIcon className="mt-0 pt-0" icon={faEnvelopeCircleCheck} /></p>}

        {<p onClick={() => {
          // previousMedicineAdd()
          setSelectedOption({ id: 7, name: "Visit history" })
        }} className="px-2 mx-2 mr-3 complete-btn">Add previous medicines <FontAwesomeIcon className="mt-0 pt-0" icon={faCapsules} /></p>}

        {!fromPatient && <p onClick={() => { setTemplateModalShow(!templateModalShow) }} className="px-2 mx-2 mr-3 complete-btn bg-info">{visitDetails?.template ? "Update visit template" : "Save visit as template"} <FontAwesomeIcon className="mt-0 pt-0" icon={faSave} /></p>}

        {!fromPatient && <div className="" style={{ zIndex: "1000" }}>
          <SearchSelect uniqKey={1} borderColor='#13AC81' handleSubmit={handleSubmit} isSearching={false} setOptionFun={setOptionFun} options={templateList?.templates} placeHolder='Search templates'></SearchSelect>
        </div>}

        {<p onClick={() => { setNextVisitModal(!nextVisitModal) }} className="px-2 mx-2 mr-1 complete-btn">Next visit instruction</p>}
        {<p onClick={() => { setReferralModal(!referralModal) }} className="px-2 mx-2 mr-3 complete-btn">Referral</p>}

        {/* <p className="px-2"><span className="text-secondary">Name: </span>{visitDetails?.patientName}</p> */}
        {visitDetails?.patientId && <p className="px-2"><span className="text-secondary">PatientId: </span>{visitDetails?.patientId}</p>}
        {/* {visitDetails?.patientPhone && <p className="px-2"><span className="text-secondary">Phone: </span>{visitDetails?.patientPhone}</p>} */}
      </div>}

      {isContentHide && <div className="patient-details-section py-3">
      </div>}
      <CustomModal isHeaderHide={true} customClass={"default"} show={show}>
        <div className="p-0" style={{ height: '60px' }}>
          <h4 className="text-center">Are you sure ?</h4>
          <div className="d-flex justify-content-center mb-0 pb-0">
            <p onClick={() => setStatusToCompleteFun()} className="success px-4 mr-2">Yes</p>
            <p onClick={() => setShow(false)} className="cancel">Cancel</p>
          </div>
        </div>
      </CustomModal>

      <CustomModal isHeaderHide={true} customClass={""} show={nextVisitModal}>
        <div className="p-0 m-0 next-visit-modal pb-2" >
          <div className="d-flex justify-content-between pb-0 px-2" style={{ backgroundColor: "#0FAC81", color: "white" }}>
            <p className="text-center my-0">Next visit instructions</p>
            <p style={{ cursor: "pointer" }} onClick={() => setNextVisitModal(false)} className="text-danger pb-0 mb-0">Close</p>
          </div>
          <div className="row m-0 p-0">
            <div className="col-md-6 m-0 py-0">
              <p className="m-0 p-0">Next visit date</p>
              <div className="d-flex">
                <input value={nextVisitInput || ""} onChange={(e) => {
                  setNextVisitDuration('')
                  setNextVisitInput(e.target.value)
                  nextVisitDateSet(e.target.value, "value")
                }} type="number"></input>
                <p className="ml-1">Days</p>
              </div>
              <input
                required
                className="form-control input"
                autoComplete="off"
                type="date"
                id="next-date"
                name="next-date"
                min={currentDate?.slice(0, 10)}
                placeholder="Enter next visit date"
                value={nextVisitDate?.slice(0, 10)}
                onChange={(e) => {
                  nextVisitDateSet(e.target.value, "input")
                }}
              />
              <p className="p-0 m-0 pt-2">or select from below</p>
              <div className="p-0 m-0 border d-flex flex-wrap">
                {nextVisitDurations?.map((duration, index) => {
                  return (
                    <p key={index} onClick={() => {
                      setNextVisitDuration(duration)
                      nextVisitDateSet(duration?.value, "value")
                    }} className={`p-0 px-2 my-1 mx-1 durations ${nextVisitDuration?.id == duration?.id ? 'active' : ""}`} >{duration?.value}</p>
                  )
                })}

              </div>
            </div>
            <div className="col-md-6 m-0">
              <p className="my-0">Reason</p>
              <textarea
                className="form-control  w-100"
                autoComplete="off"
                type="text"
                rows={2}
                placeholder="reason"
                value={nextVisitInstruction || ""}
                onChange={(e) => {
                  // setInfoFun("reasonForVisit", e.target.value);
                  setNextVisitInstruction(e.target.value)
                }}
              />
              <div className="mt-2">
                {
                  defaultInstruction?.map((data, index) => {
                    return (
                      <div className="form-check form-check-inline " key={index} >
                        <input checked={defaultReason?.id == data?.id ? true : false} onChange={(e) => {
                          checkDefaultAndAdd(e.target.checked, data)
                        }} className="form-check-input" type="checkbox" id={`inlineCheckbox${data?.id}`} value="option1" />
                        <label style={{ fontSize: "12px" }} className="form-check-label" for={`inlineCheckbox${data?.id}`}>{data?.value}</label>
                      </div>
                    )
                  })
                }
              </div>

            </div>
            <div className="col-12 border d-flex justify-content-center pt-3">
              <p className="px-3" onClick={() => { handleNextVisitSubmit() }} style={{ backgroundColor: "#0FAC81", borderRadius: "4px", color: "white" }}>Save</p>
              <p onClick={() => setNextVisitModal(false)} className="bg-danger px-2 ml-2" style={{ borderRadius: "4px", color: "white" }}>Close</p>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal isHeaderHide={true} customClass={"default"} show={showModal}>
        <div className="p-0" style={{ height: '60px' }}>
          <h4 className="text-center">Back to the list ?</h4>
          <div className="d-flex justify-content-center mb-0 pb-0">
            <p onClick={() => backToListFun()} className="success px-4 mr-2">Yes</p>
            <p onClick={() => setShowModal(false)} className="cancel">No</p>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        size="xl"
        show={showPrint}
        setShow={setShowPrint}
        isHeaderHide={true}
      >
        <PrescriptionPrint setShowPrint={setShowPrint} patientId={visitDetails?.patient || patientId} visitDetails={visitDetails}></PrescriptionPrint>
      </CustomModal>
      <CustomModal
        size="md"
        show={showSentBox}
        setShow={setShowSentBox}
        isHeaderHide={true}
        autoClose={true}
      >
        <SendPrescription setShow={setShowSentBox} patientDetails={patientDetails} doctorId={visitDetails?.doctor} patientId={visitDetails?.patient} visitId={visitDetails?._id}></SendPrescription>
      </CustomModal>

      <CustomModal
        size="sm"
        show={templateModalShow}
        isHeaderHide={true}
      // setShow={setShow}
      >
        <div className='mt-0  create-template px-2 py-2'>
          <label className='mt-0 pt-0 d-block'>Create new template</label>
          <input onChange={(e) => {
            setTemplateName(e.target.value)
          }} placeholder='Enter template name'></input>
          <div className='text-center mt-2'>
            <button onClick={() => {
              createNewTemplate()
            }} className='btn py-0 mr-1'>Create
            </button>
            <button onClick={() => setTemplateModalShow(!templateModalShow)} className='btn py-0'>Cancel</button>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        size="md"
        show={referralModal}
        isHeaderHide={true}
      // setShow={setShow}
      >
        <div className='mt-0  create-template px-2 py-2'>
          <label className='mt-0 pt-0 d-block'>Write referral</label>
          <textarea
            className="form-control w-100"
            autoComplete="off"
            type="text"
            style={{ fontSize: "14px", padding: "4px" }}
            rows={4}
            placeholder="reason"
            value={referralInstruction || ""}
            onChange={(e) => {
              // setInfoFun("reasonForVisit", e.target.value);
              setReferralInstruction(e.target.value)
            }}
          />
          {referralNotes?.length > 0 && <p className="pb-0 mb-0 mt-2">Templates</p>}
          {
            referralNotes?.length > 0 && referralNotes?.map((data, index) => {
              return <div className="py-0 my-0" key={index}>
                <p style={{ fontSize: "13px" }} className="py-0 my-0">&#8226; {data} <span
                  onClick={() => {
                    setReferralInstruction((referralInstruction?.length > 0 ? (referralInstruction + "\n") : "") + data)
                  }}
                  style={{ cursor: "pointer" }} className="bg-success px-2 rounded">Use</span></p>
              </div>
            })
          }
          <div className='text-center mt-2'>
            <button onClick={() => {
              handleReferral()
            }} className='btn py-0 mr-1'>Save
            </button>
            <button onClick={() => setReferralModal(!referralModal)} className='btn py-0'>Cancel</button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Header;

import React, { useEffect, useRef, useState } from 'react'
import "./creatable-search-select.scss"
const CreatableSearchSelect = ({ customClass = "input-color-border", isDisabled = false, placeHolder = "search", isCreatable = true, options, setOptionFun, handleSubmit, handleCreate, isSearching }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const passSearchQuery = ((event) => {
    setOptionFun(event)
  })
  const [show, setShow] = useState(false)
  const ref = useRef();
  const [onOptionClick, setOnOptionClick] = useState(true)
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      setOnOptionClick(true)
      if (
        show &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShow(false);
        setOnOptionClick(false)
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [show])

  return (
    <div className='creatable-search-select' ref={ref} onClick={() => { setShow(true) }}>
      <input className={`${customClass}`} disabled={isDisabled} value={searchQuery || ""} type='text' placeholder={placeHolder} onChange={(event) => {
        setSearchQuery(event.target.value)
        passSearchQuery(event)
      }} />
      <div className={`option-section ${onOptionClick && options?.length > 0 && show && "fixed-height"}`} >
        {onOptionClick && options?.length > 0 && show ? <div>
          {options ? options?.map((option, index) => {
            return (<div className='single-option' key={index} onClick={() => {
              setSearchQuery('')
              handleSubmit(option)
              setOnOptionClick(false)
            }}>
              <p>{option?.name || option?.details || option?.reasonForVisit||option?.historyOfPresetIllness||option?.differentialDx||option?.provisionalDx}</p>
            </div>)
          }) : ""}
        </div> : ""}
        {/* {isSearching && <p className='px-1'>Searching...</p>} */}
        {onOptionClick && searchQuery.length > 1 ? <div className='d-flex single-option' onClick={() => {
          setSearchQuery('')
          handleCreate(searchQuery)
          setOnOptionClick(false)
        }}>
          <p className='text-success'>Create( {searchQuery} )</p>
        </div> : ""}

        {!isCreatable && options?.length === 0 && searchQuery.length > 1 ? <div className='d-flex single-option' onClick={() => {
          // setSearchQuery('')
          handleCreate(searchQuery)
          setOnOptionClick(false)
        }}>
          <p>Not found</p>
        </div> : ""}

      </div>
    </div>
  )
}

export default CreatableSearchSelect
